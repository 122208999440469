<template>
    <zw-sidebar @shown="shown" :title="$t('stock.title.stock_move')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div>
                <b-row>
                    <b-col cols="12">
                        <zw-select-group v-model="form.from"
                                         :options="getStocks()"
                                         name="from_stock"
                                         :label-prefix="labelPrefix"
                                         value-field="id"
                                         text-field="label"
                                         validate="required"
                        >
                        </zw-select-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-select-group v-model="form.to"
                                         :options="getStocks()"
                                         name="to_stock"
                                         :label-prefix="labelPrefix"
                                         value-field="id"
                                         text-field="label"
                                         validate="required"
                        >
                        </zw-select-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group v-model="form.count"
                                        name="count"
                                        :label-prefix="labelPrefix"
                                        type="number"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group v-model="form.comment"
                                        name="comment"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'StockMoveModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: {},
            labelPrefix: 'stock.label.',
            racks: {},
            shelves: {},
        }
    },
    methods: {
        ...mapGetters('Stock', ['getStocks']),
        shown() {
            this.form.article_id = this.payload.article_id
            if (this.payload.from_id) {
                this.form.from = this.payload.from_id
            }
            const stocks = this.$store.dispatch('Stock/fetchStockList', this.form.article_id)

            Promise.all([stocks])
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Stock/move', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>